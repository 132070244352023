import React from "react"
import { checkFormat } from "../common/checkFormat"
import Footer from "../components/landing/footer"
import Header from "../components/landing/header"
import Page404 from "../components/landing/page404"

const NotFound = ({lang}) => {
  const [loader, setLoader] = React.useState(true)

  React.useEffect(() => {
    if (window) {
      checkFormat().then(result => {
        window.localStorage.setItem("images-extension", result)
        setLoader(false)
      })
    }
  }, [])

  return (
    <>
      {!loader ? (
        <>
          <Header  lang={lang}/>
          <Page404  lang={lang}/>
          <hr />
          <Footer  lang={lang}/>
        </>
      ) : (
        ""
      )}
    </>
  )
}

export default NotFound
