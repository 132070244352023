import React from "react"

const Page404 = () => {
  return (
    <div className="container">
      <div className="grid-container page-not-found">
        <h1 className="gradient bold">404</h1>
        <h5 className="semi-bold">Strona o podanym adresie nie istnieje</h5>
      </div>
    </div>
  )
}

export default Page404
