import * as React from "react"
import Button from "../../common/button/button"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useLocation } from "@reach/router"
import logo from "../../assets/images/svg/ar-labs-logo.svg"
import { Links } from "../../const/links"
import { t } from "../../utils/translationUtils"
import { languages } from "../../const/languages"
import { navigateLink } from "../../common/navigateLink"
import { imageBackgroundStyle } from "../../const/image"


const Footer = ({lang  = languages.EN}) => {
  const [text, setText] = React.useState();
  const location = useLocation()
  const path = location.pathname

  React.useEffect(() => {
    setText(() => { return require(`../../translations/landing/${lang}.json`).footer})
  }, [])

  return (
    text &&
    <div className="card footer-card">
      <div
        className="container"
        style={{ paddingTop: "80px", paddingBottom: "0" }}
      >
        <div className="grid-container footer grid-template-3-columns">
          <div className="first">
            <div className="item-top footer-logo-small">
              <img
                src={logo}
                width={210}
                height={85}
                alt="logoSmall"
                style={{ margin: "auto" }}
              />
            </div>
            <div className="item">
              <div className="subtitle-2 light black-text">
                {t(text.description)}
              </div>


          <div className="footer-app-buttons grid-template-2-columns">
            <Button
              className="button-app-store button-app-store-large"
              name="App Store"
              style={imageBackgroundStyle("app-store-large")}
              onClick={() => {
                const link = lang === languages.EN ? Links.appstore : Links[`appstore_${lang}`];
                window.open(link)
              }}
            />
            <Button
              className="button-play-store button-app-store-large"
              name="Google Play Store"
              style={imageBackgroundStyle("google-play-large")}
              onClick={() => {
                const link = lang === languages.EN ? Links.playstore : Links[`playstore_${lang}`];
                window.open(link)
              }}
            />
          </div>
          </div>

          </div>
          <div>
            <div className="item-top nav">
              <h5>{t(text.navigation)}</h5>
            </div>
            <div className="item grid-template-2-columns">
              <div
                style={{ display: "grid", gridTemplateRows: "1fr 1fr 1fr 1fr" }}
              >
                <Button
                  className="semi-bold button-link"
                  text={t(text.benefits)}
                  onClick={() => {
                    navigateLink("/benefits",lang)
                  }}
                />
                <Button
                  className="semi-bold button-link"
                  text={t(text.services)}
                  onClick={() => {
                    navigateLink("/services",lang)
                  }}
                />
                <Button
                  className="semi-bold button-link"
                  text={t(text.possibilities)}
                  onClick={() => {
                    navigateLink("/possibilities", lang)
                  }}
                />
                <Button
                  className="semi-bold button-link"
                  text={t(text.virtual_try_on)}
                  onClick={() => {
                    navigateLink("/vto", lang)
                  }}
                />
              </div>
              <div
                style={{ display: "grid", gridTemplateRows: "1fr 1fr 1fr 1fr" }}
              >
                <Button
                  className="semi-bold button-link"
                  text={t(text.customers)}
                  onClick={() => {
                    (path === "/" ||  path ===`/${lang}`)
                      ? scrollTo("#customers")
                      : navigateLink("/#customers", lang)
                  }}
                />
                <Button
                  className="semi-bold button-link"
                  text={t(text.team)}
                  onClick={() => {
                    (path === "/" ||  path ===`/${lang}`)? scrollTo("#team") : navigateLink("/#team", lang)
                  }}
                />
                <Button
                  className="semi-bold button-link"
                  text={t(text.blog)}
                  onClick={() => {
                    navigateLink("/blog", lang)
                  }}
                />
                <Button
                  className="semi-bold button-link"
                  text={t(text.faq)}
                  onClick={() => {
                    navigateLink("/faq", lang)
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="item-top nav">
              <h5>{t(text.social_media)}</h5>
            </div>
            <div className="item icons">
              <div
                className="facebook"
                onClick={() => {
                  window.open(Links.facebook)
                }}
              ></div>
              <div
                className="linkedin"
                onClick={() => {
                  window.open(Links.linkedin)
                }}
              ></div>
              {/* <div className="youtube" onClick={() => {window.open("https://www.youtube.com/channel/UCkSXsJyPDBSotOS5TX-DMBA") }}></div>  */}
              <div
                className="instagram"
                onClick={() => {
                  window.open(Links.instagram)
                }}
              ></div>
            </div>
          </div>
        </div>
        <hr className="gradient" />
        <div className="grid-container grid-template-2-columns footer">
          <div className="body-2 gradient">
            © All Rights Reserved AR-Labs.io 2023
          </div>
          <div
            className="grid-container gradient"
            style={{ justifyItems: "right" }}
          >
            <div
              className="body-2 gradient"
              style={{ paddingRight: "10px", cursor: "pointer" }}
              onClick={() => {
                const link = lang === languages.EN ? Links.privacyPolicy : Links[`privacyPolicy_${lang}`];
                window.open(link)
              }}
            >
              {t(text.privacy_policy)}
            </div>
            {/* <div className="body-2 gradient" style={{cursor: 'pointer'}} onClick={() => {window.open("https://app.ar-labs.io/privacy/arlabs")}}>Terms of Use</div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
